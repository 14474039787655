import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'

import Layout from "../components/layout"

import RobotSim1 from '../images/robotsim/robotsim1.jpg';
import RobotSim2 from '../images/robotsim/robotsim2.jpg';
import RobotSim3 from '../images/robotsim/robotsim3.jpg';

import Posthuman1 from '../images/posthuman/posthuman1.jpg';
import Posthuman2 from '../images/posthuman/posthuman2.jpg';
import Posthuman3 from '../images/posthuman/posthuman3.jpg';

import Forklift from '../images/generic/forklift.jpg';

const ProjectsPage = ({ data: { site: { siteMetadata: { title, siteUrl } } }, location: { pathname } }) =>
  <Layout>
    <div className="ProjectsPage">
      <Helmet
        title={`Projects | ${title}`}
        meta={[
          { property: 'og:title', content: `Projects | ${title}` },
          { property: 'og:url', content: `${siteUrl}${pathname}` },
        ]}
      />

      <h1 className="hi">Recent Projects</h1>

      <div className="ProjectSection">
        <h3>
          <a href="https://github.com/connerfritz/imagetune">ImageTune <span className="TimeActive">(JS, 2019)</span></a>
        </h3>
        <p>
          <a className="BodyLink" href="https://github.com/connerfritz/imagetune">ImageTune</a> takes image files, and handles resize and conversion in the browser prior to upload, saving server processing time and storage space while reducing upload times.
        </p>
        <p>
          I created this project because many websites seem to have issues uploading images, notably as the quality and resolution of smartphone cameras has improved many websites enforced 10mb image limits are no longer viable. I found a few sites wouldn't accept uploads from my iPhone because the camera photos are just too large.
        </p>
        <p>
          This project allows people to select high-resolution files directly from their phone or computer, but only uploads a web-appropriate size and format, saving bandwidth, cutting upload times and reducing the image processing load on servers.
        </p>
      </div>

      <div className="ProjectSection">
        <h3>
          Unannounced App <span className="TimeActive">(Swift, 2018-present)</span>
        </h3>
        <p>
          
        </p>
      </div>

      <div className="ProjectSection">
        <h3>
          Unannounced Website <span className="TimeActive">(Phoenix, 2018-present)</span>
        </h3>
        <p>
          
        </p>
      </div>

      <div className="ProjectSection">
        <h3>
          Unannounced Game <span className="TimeActive">(Unity, 2018-present)</span>
        </h3>
        <p>
          
        </p>
      </div>

      <div className="ProjectSection">
        <h3>
          Posthuman <span className="TimeActive">(Unity, 2017-2018)</span>
        </h3>
        <p>
          Posthuman is a 2D space game concept involving building custom spaceships from components scavenged from the battlefield.
        </p>
        <p>
          It included custom 2D lighting shaders that gave the game a unique feeling of grittiness and realism, despite its 2D setting.
        </p>
        <p>
          <Link to="/other" className="BodyLink">Click here to see Posthuman poster and concept art.</Link>
        </p>
        <p>
          <div style={{ width: '100%', height: 0, paddingBottom: '66%', position: 'relative' }}><iframe src="https://giphy.com/embed/3ohs7TwQdOAxGbAPUk" title="gif of posthuman" width="100%" height="100%" style={{position: 'absolute'}} frameBorder="0" className="giphy-embed" allowFullScreen></iframe></div>
        </p>
        <p>
          <img src={Posthuman1} alt="Posthuman game screenshot" />
        </p>
        <p>
          <img src={Posthuman2} alt="Posthuman game screenshot" />
        </p>
        <p>
          <img src={Posthuman3} alt="Posthuman game screenshot" />
        </p>
      </div>

      <div className="ProjectSection">
        <h3>
          Robot Vacuum Sim <span className="TimeActive">(Unity, 2017)</span>
        </h3>
        <p>
          Robot Vacuum Sim was a game concept I worked on briefly involving a robotic vacuum avenging it's owner's death after rolling over a handgun.
        </p>
        <p>
          <img src={RobotSim1} alt="Robot sim game screenshot" />
        </p>
        <p>
          <img src={RobotSim2} alt="Robot sim game screenshot" />
        </p>
        <p>
          <img src={RobotSim3} alt="Robot sim game screenshot" />
        </p>
      </div>

      <div className="ProjectSection">
        <h3>
          Unannounced Game <span className="TimeActive">(Unity, 2017)</span>
        </h3>
        <p>
          
        </p>
      </div>

      <div className="ProjectSection">
        <h3>
          Forklift Game <span className="TimeActive">(Unreal Engine, 2016)</span>
        </h3>
        <p>
          Forklift party game where players had to operate overpowered forklifts to complete party games.
        </p>
        <p>
          <img src={Forklift} alt="Forklift game screenshot" />
        </p>
      </div>

      <div className="ProjectSection">
        <h3>
          <a href="https://github.com/connerfritz/thousandwords">ThousandWords <span className="TimeActive">(JS, 2014-present)</span></a>
        </h3>
        <p>
          <a className="BodyLink" href="https://github.com/connerfritz/thousandwords">ThousandWords</a> is a browser-based image cropper and resizer.
        </p>
      </div>
    </div>
  </Layout>

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

export default ProjectsPage
